import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import ScrollAnim from "react-animate-on-scroll"
import Contact from "../components/contact-slice"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Picture from "../images/blog-post.jpg"
import miksiImg from "../images/some-ja-nuoret/miksi-nuoret-käyttävät.png"
import Some2Picture from "../images/some.jpg"
import BottomPicture from "../images/svg/note-list.svg"
import TuntikirjaPicture from "../images/tuntikirja2.jpg"
import WebPicture from "../images/web-1.jpg"

export default () => (
  <Layout>
    <SEO
      title={"Tuotteet"}
      description={
        "Tuntikirja.fi on ratkaisu työajan seurantaan. Se on helppokäyttöinen ja järkihintaan aina saatavilla oleva verkkopalvelu."
      }
      image={Picture}
    />
    <Header siteTitle={"Tuotteet"} />
    <section className="section-process slice slice-lg pt-4">
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6 order-lg-2">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Verkkopalvelut</h3>
                <p className="lead my-4">
                  Päästä paperilaput ja excel-tiedostot eläkkeelle ja siirry
                  nykyaikaan. Liiketoimintaprosessien järkeistäminen sähköiseen
                  muotoon tuo kustannussäästöä, selkeyttä ja tehokkuutta
                  tekemiseen. Ennen kaikkea se säästää työntekijöidesi ja
                  asiakkaidesi hermoja, kun eri työvaiheet saadaan dokumentoitua
                  samaan verkkopalveluun.
                </p>
              </div>
              <Link
                to={"/palvelut"}
                className="btn btn-primary rounded-pill mt-5"
              >
                Lue lisää
              </Link>
            </div>
            <div className="col-lg-5 order-lg-1">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Verkkopalvelut"
                  src={WebPicture}
                  className="img-center img-fluid rounded-lg shadow-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Tuntikirja.fi</h3>
                <p className="lead my-4">
                  Tuntikirja.fi on ratkaisu työajan seurantaan. Se on
                  helppokäyttöinen ja järkihintaan aina saatavilla oleva
                  verkkopalvelu. Palvelussa on tuntikirjanpito ja työnseuranta
                  löytyvät samasta osoitteesta. Palvelu sopii erinomaisesti
                  yrityksellesi, jos kirjaatte tunnit ja työt tuntikohtaisesti
                  tai laskutatte asiakkaitanne tuntityöstä.
                </p>
                <div className="mt-5">
                  <ul className="list-unstyled">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                            <FontAwesomeIcon icon="users" />
                          </div>
                        </div>
                        <div>
                          <span className="h6 mb-0">
                            Täydellinen valinta 1-20 henkilön yritykselle.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-shape icon-warning icon-sm rounded-circle mr-3">
                            <FontAwesomeIcon icon="money-bill-wave" />
                          </div>
                        </div>
                        <div>
                          <span className="h6 mb-0">
                            Maksuton kuukauden mittainen kokeilujakso.
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <a
                  href={"https://tuntikirja.fi"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className="btn btn-primary rounded-pill mt-5"
                >
                  Kokeile nyt
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Tuntikirja"
                  src={TuntikirjaPicture}
                  className="img-center img-fluid rounded-lg shadow-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-lg-5">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Sosiaalisen median palvelut"
                  src={miksiImg}
                  className="img-center img-fluid shadow-lg rounded-lg"
                />
              </ScrollAnim>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Some ja nuoret -katsaus</h3>
                <p className="lead my-4">
                  Some ja nuoret -katsaus on koko Suomen kattava verkkokysely
                  suomalaisten nuorten ja nuorten aikuisten sosiaalisen median
                  käytöstä. Kyselyn vastaajina ovat 13-29 -vuotiaat, Suomessa
                  asuvat ihmiset. Kyselyä on toteutettu jo vuodesta 2013 alkaen.
                  Kyselyt on tehty yhteistyössä Oulun kaupungin sivistys- ja
                  kulttuuripalveluiden kanssa. Viimeisin kysely tavoitti 6247
                  nuorta eri puolilta Suomea.{" "}
                </p>
                <Link
                  to={"/some-ja-nuoret"}
                  className="btn btn-primary rounded-pill mt-5"
                >
                  Lue lisää
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-stepvb">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Sosiaalisen median palvelut</h3>
                <p className="lead my-4">
                  Valjasta sosiaalinen media hyötykäyttöön. Toteutamme
                  asiakashankintaa asiakkaidemme sosiaalisen median kanaviin
                  "avaimet käteen" -periaatteella. Rakentamiemme sovellusten ja
                  maksetun mainonnan yhdistelmällä saat kanavillesi uusia
                  seuraajia sekä jalostettua jo olemassa olevista seuraajista
                  liidejä.
                  <br />
                  <br />
                  Meidän kautta onnistuu mm. facebook -kilpailusovellukset,
                  -asiakastyytyväisyyskyselyt, -joulukalenterit sekä erilaiset
                  räätälöidyt koodikilpailut.
                </p>
              </div>
              <Link
                to={"/yhteystiedot"}
                className="btn btn-primary rounded-pill mt-5"
              >
                Ota yhteyttä
              </Link>
            </div>
            <div className="col-lg-5 order-lg-1">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Sosiaalisen median palvelut"
                  src={Some2Picture}
                  className="img-center img-fluid rounded-lg shadow-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      className={
        "container slice-lg pt-0 justify-content-center d-none d-md-flex"
      }
    >
      <ScrollAnim
        animateIn="fadeIn"
        duration="0.5"
        animateOnce="true"
        offset="100"
        className={"d-none d-md-flex justify-content-center"}
      >
        <img src={BottomPicture} className={"w-50"} alt={"Footer"} />
      </ScrollAnim>
    </div>
    <Contact />
  </Layout>
)
